// @flow

import type { Element } from 'react'
import React from 'react'
import { BodyText, Heading1 } from '../../../components/typography'

function CottagesIntro(): Element<'div'> {
  return (
    <div id="intro">
      <div className="bg-purple text-white relative">
        <div className="pt-12 pb-2 md:pt-16 px-8">
          <div className="w-full max-w-xl mx-auto text-center">
            <Heading1 isTitle className="pb-4">
              Our Cottages
            </Heading1>
            <div className="md:w-3/5 mx-auto">
              <BodyText className="pb-5 leading-normal text-purple-lightest">
                Cradled in the lively, peaceful and balmy town of Fort Myers
                Beach Florida, Cottages of Paradise Point makes a perfect
                vacation home getaway.
              </BodyText>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CottagesIntro
