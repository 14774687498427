// @flow

import React from 'react'
import type { Element } from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import GalleryTile from './gallery-tile'
import { galleryCottages } from './data/cottages'

import type { GalleryCottageRef, GalleryCottage } from '../types'
import type { CottageName } from '../../../types/cottages'

type CottageGalleryImage = {
  id: string,
  title: string,
  description: string,
  image: {
    src: string,
  },
}

type QueryResponse = {|
  allGalleryImages: {
    images: CottageGalleryImage[],
  },
  gulfImage: CottageGalleryImage,
|}

const cottageMatchesKey =
  (cottage: GalleryCottageRef) =>
  ({ title }) =>
    cottage.fileTitle.toLowerCase() === title

function CottagesGallery(): Element<'div'> {
  const data: QueryResponse = useStaticQuery(graphql`
    query CottagesGalleryQuery {
      allGalleryImages: contentfulPage(slug: { eq: "Gallery" }) {
        images {
          id
          title
          description
          image: fixed(width: 500, quality: 80) {
            src
          }
        }
      }
      gulfImage: contentfulAsset(title: { eq: "gulf-primary" }) {
        id
        title
        description
        image: fixed(width: 1000, quality: 75) {
          src
        }
      }
    }
  `)

  const cottageThumbnails: GalleryCottage[] = galleryCottages.map((cottage) => {
    // special treatment for the primary image
    if (cottage.name.toLowerCase() === 'gulf') {
      return {
        ...cottage,
        image: {
          src: data.gulfImage.image.src,
          alt: data.gulfImage.description,
        },
      }
    }

    const galleryItemRes: CottageGalleryImage | null =
      data.allGalleryImages.images.find(cottageMatchesKey(cottage)) || null

    if (!galleryItemRes) {
      return {
        ...cottage,
        image: { src: '', alt: '' },
      }
    }

    return {
      ...cottage,
      image: {
        src: galleryItemRes.image.src,
        alt: galleryItemRes.description,
      },
    }
  })

  const getCottageThumbnail = (
    cottageName: CottageName,
    thumbnails: GalleryCottage[],
  ): GalleryCottage => {
    const cottage = thumbnails.find(
      (thumbnail) => thumbnail.name === cottageName,
    )
    if (!cottage) throw new Error('Provided cottageName does not match data')
    return cottage
  }

  const cottage = (name) => getCottageThumbnail(name, cottageThumbnails)

  return (
    <div className="bg-purple text-white relative pb-3">
      <div className="pt-12 pb-2 md:pt-16 md:pb-12 px-8">
        <div className="w-full max-w-md mx-auto">
          <div className="-m-5">
            <ul className="pb-12 md:pb-16">
              <div className="mb-6">
                <GalleryTile cottage={cottage('Gulf')} className="h-64" />
              </div>
              <div className="flex mb-6">
                <GalleryTile cottage={cottage('Island')} className="h-64" />
                <GalleryTile cottage={cottage('Seahorse')} className="h-64" />
              </div>
              <div className="flex mb-6">
                <GalleryTile cottage={cottage('Mermaid')} className="h-64" />
                <GalleryTile cottage={cottage('Coconut')} className="h-64" />
              </div>
              <div className="flex mb-6">
                <GalleryTile cottage={cottage('Sun')} className="h-64" />
                <GalleryTile cottage={cottage('Dolphin')} className="h-64" />
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CottagesGallery
