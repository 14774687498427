// @flow

import React from 'react'
import type { Node } from 'react'

import cx from 'classnames'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { rgba } from 'polished'

import theme from '../../../../tailwind'
import { Heading4 } from '../../../components/typography'

import type { GalleryCottage } from '../types'

function TileLink({
  to,
  children,
  background,
  className,
}: {
  to: string,
  children: Node,
  background: string,
  className?: string,
}) {
  return (
    <StyledLink
      className={cx(
        'relative rounded w-full bg-grey md:shadow-lg text-white h-full block',
        className,
      )}
      background={background}
      to={to}
    >
      {children}
    </StyledLink>
  )
}
TileLink.defaultProps = {
  className: null,
}

function GalleryTile({
  cottage,
  className,
}: {
  cottage: GalleryCottage,
  className?: string,
}): Node {
  return (
    <StyledListItem className={cx('w-full', className)}>
      <TileLink
        className={className}
        background={cottage.image.src}
        to={cottage.pageRoute}
      >
        <StyledTextWrapper className="absolute pin-b pin-x rounded-b">
          <StyledHeading4 className="py-5 px-4 text-left mt-auto">
            {cottage.name}
          </StyledHeading4>
        </StyledTextWrapper>
      </TileLink>
    </StyledListItem>
  )
}
GalleryTile.defaultProps = {
  className: undefined,
}

export default GalleryTile

const StyledLink = styled(Link)`
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center 25%;
  transition: all 250ms ease-in-out;
`

const StyledTextWrapper = styled.div`
  background-color: ${rgba(theme.colors.purple, 0.96)};

  @supports (backdrop-filter: blur(1px)) {
    backdrop-filter: blur(5px) saturate(200%);
    background-color: ${rgba(theme.colors.purple, 0.5)};
  }
`

const StyledListItem = styled.li`
  &:not(:last-child) {
    margin-right: 1rem;
  }

  @media screen and (min-width: ${theme.screens.md}) {
    transition: all 250ms ease-in-out;

    &:hover ${StyledLink} {
      background-position: center 20%;
    }
  }
`

const StyledHeading4 = styled(Heading4)`
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
`
