// @flow

import type { Node } from 'react'
import React from 'react'
import Layout from '../components/layout'

import {
  CottagesIntro as Intro,
  CottagesGallery as Gallery,
} from '../scenes/cottages'

function CottagesPage(): Node {
  return (
    <Layout>
      <div className="bg-purple text-white">
        <Intro />
        <div className="pb-12">
          <Gallery />
        </div>
      </div>
    </Layout>
  )
}

export default CottagesPage
