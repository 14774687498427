// @flow

import routes from '../../../../routes'

import type { GalleryCottageRef } from '../../types'

export const galleryCottages: GalleryCottageRef[] = [
  {
    name: 'Gulf',
    fileTitle: 'gulf-primary',
    pageRoute: routes.listings.gulf,
  },
  {
    name: 'Island',
    fileTitle: 'island-exterior',
    pageRoute: routes.listings.island,
  },
  {
    name: 'Seahorse',
    fileTitle: 'seahorse-primary',
    pageRoute: routes.listings.seahorse,
  },
  {
    name: 'Mermaid',
    fileTitle: 'mermaid-exterior',
    pageRoute: routes.listings.mermaid,
  },
  {
    name: 'Coconut',
    fileTitle: 'coconut-exterior',
    pageRoute: routes.listings.coconut,
  },
  {
    name: 'Sun',
    fileTitle: 'sun-exterior',
    pageRoute: routes.listings.sun,
  },
  {
    name: 'Dolphin',
    fileTitle: 'dolphin-exterior',
    pageRoute: routes.listings.dolphin,
  },
]

export default galleryCottages
